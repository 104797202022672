<template>
  <v-container class="fill-height">
    <v-row align="center" justify="center">
      <v-col cols="12" md="8">
        <v-card class="pa-4">
          <v-card-title>Your Company Profile</v-card-title>

          <!-- URL Input and Generate Description Button -->
          <v-text-field
            v-model="companyUrl"
            label="Company Homepage URL"
            :disabled="isGenerating"
            class="mb-4"
          ></v-text-field>

          <v-btn
            :loading="isGenerating"
            :disabled="isGenerating || !companyUrl"
            @click="generateDescription"
            class="mb-4"
            color="primary"
          >
            Generate Description
          </v-btn>

          <!-- Display Generated Description -->
          <v-card v-if="generatedDescription" class="mb-4">
            <v-card-text>
              <strong>Generated Description:</strong>
              <p>{{ generatedDescription }}</p>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="acceptGeneratedDescription">
                Accept Description
              </v-btn>
            </v-card-actions>
          </v-card>

          <!-- Company Description Textarea -->
          <v-textarea
            v-model="companyDescription"
            label="Company Description"
            rows="6"
            class="mb-4"
          ></v-textarea>

          <v-btn
            :loading="isLoading"
            @click="saveDescription"
            color="primary"
          >
            Save Description
          </v-btn>
        </v-card>
      </v-col>
    </v-row>

    <!-- Snackbar Notification -->
    <v-snackbar
      v-model="showSnackbar"
      :color="snackbarColor"
      timeout="3000"
      top
    >
      {{ snackbarMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { useAuth0 } from '@auth0/auth0-vue';
import axios from 'axios';

export default {
  name: 'CompanyPage',
  data() {
    return {
      companyDescription: '',
      companyUrl: '',
      generatedDescription: '',
      isLoading: false,
      isGenerating: false,
      showSnackbar: false,
      snackbarMessage: '',
      snackbarType: 'success', // 'success' or 'error'
    };
  },
  async mounted() {
    try {
      const token = await this.getAccessTokenSilently();
      const response = await axios.get('/api/company-description', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      this.companyDescription = response.data.description;
    } catch (error) {
      console.error('Error fetching company description:', error);
    }
  },
  setup() {
    const { getAccessTokenSilently } = useAuth0();
    return { getAccessTokenSilently };
  },
  computed: {
    snackbarColor() {
      return this.snackbarType === 'success' ? 'green' : 'red';
    },
  },
  methods: {
    async saveDescription() {
      this.isLoading = true;
      try {
        const token = await this.getAccessTokenSilently();
        await axios.post(
          '/api/company-description',
          { description: this.companyDescription },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.showSnackbarNotification('Company description saved successfully!', 'success');
      } catch (error) {
        console.error('Error saving company description:', error);
        this.showSnackbarNotification('Failed to save company description', 'error');
      } finally {
        this.isLoading = false;
      }
    },
    async generateDescription() {
      if (!this.companyUrl) return;
      this.isGenerating = true;
      try {
        const response = await axios.post('/api/summarize-company', { url: this.companyUrl });
        this.generatedDescription = response.data.description;
        this.showSnackbarNotification('Description generated successfully!', 'success');
      } catch (error) {
        console.error('Error generating company description:', error);
        this.showSnackbarNotification('Failed to generate description. Please try again.', 'error');
      } finally {
        this.isGenerating = false;
      }
    },
    acceptGeneratedDescription() {
      this.companyDescription = this.generatedDescription;
      this.generatedDescription = '';
      this.showSnackbarNotification('Description accepted!', 'success');
    },
    showSnackbarNotification(message, type) {
      this.snackbarMessage = message;
      this.snackbarType = type;
      this.showSnackbar = true;
      setTimeout(() => {
        this.showSnackbar = false;
      }, 3000);
    },
  },
};
</script>
