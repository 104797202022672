import { createRouter, createWebHistory } from "vue-router";
import { authGuard } from "@auth0/auth0-vue";
import HomePage from "./views/HomePage.vue";
import PaymentPage from "./views/PaymentPage.vue";
import CompanyPage from "./views/CompanyPage.vue";
import SocialMediaPage from "./views/SocialMediaPage.vue";
import NewsletterPage from "./views/NewsletterPage.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
  },
  {
    path: "/social-media",
    name: "SocialMedia",
    component: SocialMediaPage,
    beforeEnter: authGuard,
  },
  {
    path: "/payment",
    name: "Payment",
    component: PaymentPage,
  },
  {
    path: "/my-company",
    name: "My Company",
    component: CompanyPage,
  },
  {
    path: '/newsletter',  // Add this route for the newsletter page
    name: 'Newsletter',
    component: NewsletterPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
    console.log(to);
    document.title = to.name;
    next();
});

export default router;
