<template>
  <v-container fluid>
    <!-- Hero Section -->
    <v-parallax
      height="600"
      src="https://cdn.vuetifyjs.com/images/parallax/material.jpg"
    >
      <v-row class="fill-height" align="center" justify="center">
        <v-col cols="12" class="text-center">
          <h1 class="display-2 font-weight-bold white--text">
            Boost Your Social Media Presence
          </h1>
          <h4 class="white--text">
            Generate engaging posts with AI-powered assistance
          </h4>
          <v-btn
            color="primary"
            large
            class="mt-5"
            @click="$router.push('/social-media')"
          >
            Get Started
          </v-btn>
        </v-col>
      </v-row>
    </v-parallax>

    <!-- Features Section -->
    <v-container class="my-12">
      <v-row>
        <v-col cols="12" md="4">
          <v-card outlined>
            <v-card-title>
              <v-icon large color="primary">mdi-robot</v-icon>
              <span class="ml-3">AI-Powered Content</span>
            </v-card-title>
            <v-card-text>
              Leverage advanced AI to generate captivating social media posts tailored for your audience.
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="4">
          <v-card outlined>
            <v-card-title>
              <v-icon large color="primary">mdi-speedometer</v-icon>
              <span class="ml-3">Save Time</span>
            </v-card-title>
            <v-card-text>
              Quickly create posts without the hassle of brainstorming and writing content yourself.
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="4">
          <v-card outlined>
            <v-card-title>
              <v-icon large color="primary">mdi-account-group</v-icon>
              <span class="ml-3">Grow Your Audience</span>
            </v-card-title>
            <v-card-text>
              Engage more users and expand your reach on social media platforms.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Call to Action Section -->
    <v-parallax
      height="400"
      src="https://cdn.vuetifyjs.com/images/parallax/material2.jpg"
    >
      <v-row class="fill-height" align="center" justify="center">
        <v-col cols="12" class="text-center">
          <h2 class="display-2 font-weight-bold white--text">
            Ready to Elevate Your Social Media?
          </h2>
          <v-btn
            color="primary"
            large
            class="mt-5"
            @click="$router.push('/payment')"
          >
            Buy Credits Now
          </v-btn>
        </v-col>
      </v-row>
    </v-parallax>

    <!-- Testimonials Section -->
    <v-container class="my-12">
      <v-row>
        <v-col cols="12">
          <h2 class="text-center mb-6">What Our Users Say</h2>
        </v-col>
        <v-col cols="12" md="4">
          <v-card>
            <v-card-text>
              "This service has transformed our social media strategy! Highly recommended."
            </v-card-text>
            <v-card-actions>
              <v-avatar>
                <v-img src="https://randomuser.me/api/portraits/men/1.jpg"></v-img>
              </v-avatar>
              <div class="ml-3">
                <strong>John Doe</strong><br>
                Marketing Manager
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card>
            <v-card-text>
              "Creating posts has never been easier. The AI suggestions are spot on!"
            </v-card-text>
            <v-card-actions>
              <v-avatar>
                <v-img src="https://randomuser.me/api/portraits/women/2.jpg"></v-img>
              </v-avatar>
              <div class="ml-3">
                <strong>Jane Smith</strong><br>
                Social Media Specialist
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card>
            <v-card-text>
              "An essential tool for any business looking to enhance their online presence."
            </v-card-text>
            <v-card-actions>
              <v-avatar>
                <v-img src="https://randomuser.me/api/portraits/men/3.jpg"></v-img>
              </v-avatar>
              <div class="ml-3">
                <strong>Mike Johnson</strong><br>
                CEO, Startup Inc.
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Newsletter Section -->
    <v-parallax
      height="300"
      src="https://cdn.vuetifyjs.com/images/parallax/material3.jpg"
    >
      <v-row class="fill-height" align="center" justify="center">
        <v-col cols="12" md="6" class="text-center">
          <h2 class="white--text">Stay Updated</h2>
          <p class="white--text">
            Subscribe to our newsletter to receive the latest news and exclusive offers.
          </p>
          <v-btn
            color="primary"
            large
            class="mt-5"
            @click="$router.push('/newsletter')"
          >
            Subscribe Now
          </v-btn>
        </v-col>
      </v-row>
    </v-parallax>

  </v-container>
</template>

<script>
export default {
  name: 'HomePage',
};
</script>

<style scoped>
.white--text {
  color: white !important;
}
</style>
