<template>
  <v-app>
    <v-app-bar app>
      <v-toolbar-title>Marketing Poster</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- Show these buttons on desktop only -->
      <v-btn text to="/" class="d-none d-md-flex">Home</v-btn>
      <v-btn text to="/social-media" class="d-none d-md-flex">Social Media</v-btn>
      <v-btn text to="/my-company" class="d-none d-md-flex">My Company</v-btn>
      <v-btn text to="/payment" class="d-none d-md-flex">Payment</v-btn>
      <v-spacer></v-spacer>
      <v-btn icon @click="toggleDrawer" class="d-flex d-md-none">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <div v-if="isAuthenticated" class="d-none d-md-flex">
        <v-chip :color="creditColorClass" class="ma-2">
          Credits: {{ credits }}
        </v-chip>
        <v-btn text @click="logout">Logout</v-btn>
      </div>
      <v-btn text v-else @click="login" class="d-none d-md-flex">Login</v-btn>
    </v-app-bar>

    <!-- Navigation Drawer for Mobile -->
    <v-navigation-drawer app v-model="drawer" temporary>
      <v-list>
        <v-list-item v-if="isAuthenticated">
          <v-chip :color="creditColorClass" class="ma-2">
            Credits: {{ credits }}
          </v-chip>
		</v-list-item>
        <v-list-item link to="/">Home</v-list-item>
        <v-list-item link to="/social-media">Social Media</v-list-item>
        <v-list-item link to="/my-company">My Company</v-list-item>
        <v-list-item link to="/payment">Payment</v-list-item>
        <v-divider></v-divider>
        <v-list-item v-if="isAuthenticated">
          <v-btn text @click="logout">Logout</v-btn>
        </v-list-item>
        <v-list-item v-else>
          <v-btn text @click="login" color="primary">Login</v-btn>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';
import axios from 'axios';

export default {
  name: 'App',
  setup() {
    const { loginWithRedirect, logout, isAuthenticated, getAccessTokenSilently, user } = useAuth0();
    const authState = ref(false);
    const credits = ref(0);
    const drawer = ref(false);

    const fetchCredits = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get('/api/credits', {
          headers: { Authorization: `Bearer ${token}` },
        });
        credits.value = response.data.credits;
      } catch (error) {
        console.error('Error fetching credits:', error);
      }
    };

    onMounted(async () => {
      try {
        await getAccessTokenSilently();
        authState.value = isAuthenticated.value;

        if (isAuthenticated.value && user.value) {
          const token = await getAccessTokenSilently();
          const userInfo = {
            auth0_id: user.value.sub,
            email: user.value.email,
            name: user.value.name,
          };

          await axios.post('/api/save-user-info', userInfo, {
            headers: { Authorization: `Bearer ${token}` },
          });

          await fetchCredits();
        }
      } catch (error) {
        console.error('Session check failed:', error);
      }
    });

    const login = () => loginWithRedirect();
    const logoutUser = () => logout({ returnTo: window.location.origin });
    const toggleDrawer = () => (drawer.value = !drawer.value);

    const creditColorClass = computed(() => {
      if (credits.value > 20) return 'green';
      if (credits.value > 10) return 'orange';
      return 'red';
    });

    return {
      login,
      logout: logoutUser,
      isAuthenticated: authState,
      credits,
      creditColorClass,
      drawer,
      toggleDrawer,
    };
  },
};
</script>
