<template>
  <v-container class="fill-height">
    <v-row align="center" justify="center">
      <v-col cols="12" md="8">
        <v-card class="pa-4">

          <v-card-title>Create Social Media Posts</v-card-title>

          <!-- Company Description -->
          <v-textarea
            v-model="companyDescription"
            label="About Your Company"
          ></v-textarea>

          <!-- Social Post Content -->
          <v-textarea
            v-model="socialPostContent"
            label="What is Your Social Post About?"
          ></v-textarea>

        <!-- Tone Slider -->
          <div class="mt-4">
            <!-- Labels Above the Slider -->
            <div class="d-flex justify-space-between px-2">
              <span>Informal</span>
              <span>Formal</span>
            </div>
            <v-slider
              v-model="postTone"
              min="0"
              max="100"
              step="1"
            ></v-slider>
          </div>

          <!-- Generate Posts Button -->
          <v-btn
            :loading="isLoading"
            :disabled="isLoading"
            @click="generatePosts"
            color="primary"
          >
            Generate Posts
          </v-btn>

          <!-- Display Generated Posts -->
          <div v-if="Object.keys(posts).length > 0" class="mt-4">
            <v-card
              v-for="(post, platformName) in posts"
              :key="platformName"
              class="mb-4"
            >
              <v-card-text>
                <v-row align="center">
                  <!-- Platform Icon -->
                  <v-col cols="auto">
                    <v-avatar>
                        <v-img :src="platformLogos[platformName]" :alt="platformName"></v-img>
                      </v-avatar>
                  </v-col>
                  <!-- Post Text -->
                  <v-col>
                    {{ post }}
                  </v-col>
                  <!-- Copy Button -->
                  <v-col cols="auto">
                    <v-btn
                      icon
                      @click="copyToClipboard(platformName, post)"
                    >
                      <v-icon :icon="copiedPlatforms[platformName] ? 'mdi-check' : 'mdi-content-copy'"></v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import linkedinLogo from '@/assets/images/linkedin_logo.png';
import twitterLogo from '@/assets/images/twitter_logo.png';
import facebookLogo from '@/assets/images/facebook_logo.png';
import { useAuth0 } from '@auth0/auth0-vue';

export default {
  name: 'SocialMediaPage',
  data() {
    return {
      companyDescription: '',
      socialPostContent: '',
      postTone: 50,
      isLoading: false,
      posts: {},
      copiedPlatforms: {},
      platformLogos: {
        linkedin: linkedinLogo,
        twitter: twitterLogo,
        facebook: facebookLogo,
      },
    };
  },
  async mounted() {
    try {
      const token = await this.getAccessTokenSilently();
      const response = await axios.get('/api/company-description', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      this.companyDescription = response.data.description || '';
    } catch (error) {
      console.error('Error fetching company description:', error);
    }
  },
  setup() {
    const { getAccessTokenSilently } = useAuth0();
    return { getAccessTokenSilently };
  },
  methods: {
    async generatePosts() {
      this.isLoading = true;
      try {
        const token = await this.getAccessTokenSilently();
        const response = await axios.post(
          '/api/generate-posts',
          {
            companyDescription: this.companyDescription,
            socialPostContent: this.socialPostContent,
            postTone: this.postTone,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.posts = response.data.posts;
        this.copiedPlatforms = {};
      } catch (error) {
        console.error('Error generating posts:', error);
      } finally {
        this.isLoading = false;
      }
    },
    copyToClipboard(platformName, post) {
      navigator.clipboard.writeText(post);
      this.copiedPlatforms[platformName] = true;
      setTimeout(() => {
        this.copiedPlatforms[platformName] = false;
      }, 2000);
    },
  },
};
</script>
